import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
import Highlight from '../../../components/worksafe-vic/Highlight';
import NumberLabel from '../../../components/worksafe-vic/NumberLabel';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "tyro-health-playground",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#tyro-health-playground",
        "aria-label": "tyro health playground permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tyro Health playground`}</h3>
    <p>{`Tyro Health has a playground to allow you to test SDK transactions. You can access Playground by  clicking on the below link:`}</p>
    <p><a parentName="p" {...{
        "href": "https://playground.medipass.io/"
      }}>{`https://playground.medipass.io/`}</a></p>
    <p>{`You will need a `}<a parentName="p" {...{
        "href": "https://help.medipass.com.au/en/articles/1142164-how-to-generate-a-medipass-api-key"
      }}><strong parentName="a">{`API Key`}</strong></a>{` and APP ID to use Playground. `}<br />{`
Once you have inserted your API Key and APP ID click on the refreash token button.`}</p>
    <h3 {...{
      "id": "sample-transcations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sample-transcations",
        "aria-label": "sample transcations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sample transcations`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://playground.medipass.io/?query=%7B%22funder%22%3A%22comcare%22%2C%22platform%22%3A%22funder%22%2C%22providerNumber%22%3A%220111827Y%22%2C%22funderData%22%3A%7B%22comcare%22%3A%7B%22serviceNotes%22%3A%22Current%20Medication%3A%20Donepezil%20HCL%22%7D%7D%2C%22invoiceReference%22%3A%22MA1567%22%2C%22memberStatus%22%3A%22new%22%2C%22patient%22%3A%7B%22firstName%22%3A%22Emily%22%2C%22lastName%22%3A%22Harris%22%2C%22dob%22%3A%221980-09-04%22%2C%22mobile%22%3A%220472637740%22%2C%22accountNumber%22%3A%221243615%2F2%22%7D%2C%22claimableItems%22%3A%5B%7B%22itemCode%22%3A%2210996%22%2C%22price%22%3A%22%242000%22%2C%22description%22%3A%22%20Service%20provided%20by%20a%20Practice%20Nurse%22%7D%5D%7D&config=%7B%22env%22%3A%22stg%22%2C%22apiKey%22%3A%22%22%2C%22appId%22%3A%22%22%7D"
          }}>{`Playground test transaction Example for a GP item `}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://playground.medipass.io/?query=%7B%22platform%22%3A%22funder%22%2C%22funder%22%3A%22comcare%22%2C%22funderData%22%3A%7B%22comcare%22%3A%7B%22serviceNotes%22%3A%22Pain%20level%20%3A7%20%22%7D%7D%2C%22invoiceReference%22%3A%22UI1567%22%2C%22memberStatus%22%3A%22new%22%2C%22patient%22%3A%7B%22firstName%22%3A%22Emily%20%22%2C%22lastName%22%3A%22Harris%20%22%2C%22dob%22%3A%221991-06-13%22%2C%22mobile%22%3A%220472637746%22%2C%22accountNumber%22%3A%2257691840%22%7D%2C%22claimableItems%22%3A%5B%7B%22itemCode%22%3A%22ELCTO%22%2C%22serviceDateString%22%3A%222022-02-15%22%2C%22price%22%3A%22%2490%22%2C%22description%22%3A%22ELECTRO%20THERAPY%20BY%20PHYSIO%22%7D%5D%2C%22providerNumber%22%3A%220111827Y%22%7D&config=%7B%22env%22%3A%22stg%22%2C%22apiKey%22%3A%22%22%2C%22appId%22%3A%22%22%7D"
          }}>{`Playground test transaction example of Physio`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://playground.medipass.io/?query=%7B%22platform%22%3A%22funder%22%2C%22funder%22%3A%22comcare%22%2C%22providerNumber%22%3A%220710446T%22%2C%22funderData%22%3A%7B%22comcare%22%3A%7B%22serviceNotes%22%3A%22Current%20Medication%3A%20Donepezil%20HCL%22%7D%7D%2C%22invoiceReference%22%3A%22MA1567%22%2C%22memberStatus%22%3A%22new%22%2C%22patient%22%3A%7B%22firstName%22%3A%22Emily%22%2C%22lastName%22%3A%22Harris%22%2C%22dob%22%3A%221980-09-04%22%2C%22mobile%22%3A%220472637740%22%2C%22accountNumber%22%3A%221243615%2F2%22%7D%2C%22claimableItems%22%3A%5B%7B%22itemCode%22%3A%22AD030%22%2C%22price%22%3A%22%24567%22%2C%22description%22%3A%22Cleaning%20%22%2C%22serviceDateString%22%3A%222022-02-15%22%7D%5D%7D&config=%7B%22env%22%3A%22stg%22%2C%22apiKey%22%3A%22%22%2C%22appId%22%3A%22%22%7D"
          }}>{`Playground test transaction example for a Home Care `}</a></p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      